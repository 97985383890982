/** @format */

.navbar {
  /* position:absolute; */
  width: 100%;
  /* z-index: 1; */
  font-weight: 400;
  font-size: 20px;
  /* background-color: #ffffff00; */
}

.navbar-expand-md .navbar-nav .nav-link{
  font-size: 20px;
  font-weight: 500;
}
.nav-contact{
  text-align: end;
}
.nav-contact h1{
  color: #1a507d;
}

.navbar-brand img{
width: 150px;
}

@media (max-width: 575.5px) {
  .navbar-brand img{
width: 100px;
}
.nav-contact h1{
  text-align: center;
}
}