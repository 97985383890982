/** @format */
.banner-section {
  position: relative;
  z-index: 0;
  opacity: 1;
}
.banner-img {
  height: 100%;
  width: 100%;
  position: static;
  top:0px
}

.banner-tag {
  position: absolute;
  top: 30%;
  bottom: 30%;
  left: 0;
  right: 50%;
}
.banner-tag {
  text-align: left;
  margin-left: 12px;
}
.banner-tag h1 {
  color: #fff;
  font-size: 65px;
  margin-bottom: 45px;
}
.material {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;

}
.material-1 {
  width: 350px;
  margin: 35px auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  transition: all 0.5s ease-in-out;
}
.material-1 img{
  border-radius: 20px;
  width: 320px;
}
.material-1 h4{
font-size: 20px;
font-weight: 500;
color: #1a507d;
}

.material-1:hover{
  margin-top: 10px;
}

.view-more {
  width: 200px;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
}

#btn {
  text-decoration: none;
  cursor: pointer;
  color: #1a507d;
  font-size: 28px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
  transition: 0.3s all ease;
  position: relative;
  margin: auto;
}

#btn:hover {
  color: #fff;
}

#btn::before {
  position: absolute;
  content: '';
  left: 0;
  z-index: -1;
  background-color: #1a507d;
  width: 45px;
  height: 45px;
  border-radius: 80px;
  transition: 0.3s all cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#btn:hover::before {
  width: 100%;
}
#btn svg {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
#btn svg path {
  fill: #fff;
}

.contact-banner {
  position: relative;
  z-index: 1;
}
.contact-form {
  position: absolute;
  top: 20%;
  left: 5%;
  right: 50%;
  bottom: 20%;
}
.header-style {
  color: #fff;
}
.Enquiry {
  text-align: center;
  /* background-color: cadetblue; */
  max-width: 100%;
  margin: auto;

}
.enquiry-form {
  max-width: 75%;
  margin: auto;
  padding-bottom: 20px;
  text-align: left;
}
.enquiry-form label {
  font-size: 25px;
  font-weight: 600;
  color: rgb(209, 204, 204);
  margin: 5px auto;
}
@media (max-width: 575.5px) {

  .banner-tag h1 {
    font-size: 17px;
    margin-bottom: 5px;
  }
  .banner-tag img {
    width: 130px;

  }
  .contact-form {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #1a507d;
  }
  .contact-banner-img {
    display: none;
  }
  .Enquiry{
    padding-top: 30px;
  }
}
