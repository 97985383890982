/** @format */

.productHead h1 {
  color: #4dbfb4;
  font-size: 50px;
}
.productHead p {
  color: #53938c;
  font-size: 20px;
}

.product-content {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 20px;
  margin: 5px;
  margin-bottom: 25px;
  transition: all 0.5s ease-in-out;
  padding: 10px;
}
.product-content h4 {
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
  color: #1a507d;
}

.product-content:hover {
  margin: -5px 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}
.ProductBrand{
display: flex;
flex-wrap: wrap;
justify-content: center;
}
.ProductBrand img{
  margin: 5px;
}
@media (max-width:576px){
  .product-content {
    margin: 25px auto;
  }
}
